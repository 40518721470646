import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { makeStyles } from "@material-ui/styles";
import ReactHtmlParser from "react-html-parser";
import Button from "@material-ui/core/Button";
import Link from "../../Link";
import styles from "./styles";

const useStyles = makeStyles((theme) => styles(theme));

export const CoreButtonBlock = (props) => {
  const classes = useStyles();

  let variant = "contained";

  if (
    typeof props?.attributes?.className !== "undefined" &&
    props?.attributes?.className !== null
  ) {
    if (props?.attributes?.className.indexOf("is-style-outline") !== -1)
      variant = "outlined";
  }

  if (props?.attributes?.url === "" && props?.attributes?.text === "") return null;

  return (
    <Link
      to={props?.attributes?.url}
      style={{ textDecoration: "none" }}
      className={classes.root}
      state={props?.attributes?.linkState}
    >
      <Button
        variant={variant}
        title={props?.attributes?.title}
        className={`${props?.attributes?.className} ${props?.attributes?.textColor}`}
        data-text-color={
          props?.attributes?.textColor !== null
            ? props?.attributes?.textColor
            : "initial"
        }
        data-bg-color={
          props?.attributes?.backgroundColor !== null
            ? props?.attributes?.backgroundColor
            : "initial"
        }
      >
        {ReactHtmlParser(props?.attributes?.text)}
      </Button>
    </Link>
  );
};

CoreButtonBlock.propTypes = {
  attributes: PropTypes.object.isRequired,
};

export const CoreButtonBlockFragment = graphql`
  fragment CoreButtonBlock on WpBlock {
    ... on WpCoreButtonBlock {
      name
      attributes {
        __typename
        ... on WpCoreButtonBlockAttributes {
          backgroundColor
          className
          gradient
          linkTarget
          placeholder
          rel
          text
          textAlign
          textColor
          title
          url
        }
      }
    }
  }
`;

// This is the string version of this that is used within previews and without WP prefixes
export const CoreButtonBlockPreview = `
... on CoreButtonBlock {
  name
  attributes {
    __typename
   ... on CoreButtonBlockAttributes {
      backgroundColor
      className
      gradient
      linkTarget
      placeholder
      rel
      text
      textAlign
      textColor
      title
      url
    }
  }
}
`;
