import React from 'react';
import { graphql } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import {buildBlocks} from "../../../services/builder";
import Typography from "@material-ui/core/Typography/Typography";
import styles from './styles';

const useStyles = makeStyles(theme => styles(theme));

export const CgbFileListBlock = (props) => {
    const blocks = buildBlocks(props.innerBlocks, props.parentAttributes, props.pageContext);
    const classes = useStyles();

    return <div className={`file-list ${props?.attributes?.className ? props?.attributes?.className : ''} ${classes.fileList} ${classes.fileListTheme}`}>
        <div className="inner">
            {props?.attributes?.blockSectionTitle !== null &&
                <Typography component='h2' variant='h2' align='center'>{props?.attributes?.blockSectionTitle}</Typography>
            }
            {blocks.map((block) => {
                return block;
            })}
        </div>
    </div>
};

export const CgbFileListBlockFragment = graphql`
  fragment CgbFileListBlock on WpBlock {
    ... on WpCgbFileListBlock {
        name
        attributes {
            blockSectionTitle
            className
        }
      }
  }`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbFileListBlockPreview = `
    ... on CgbFileListBlock {
        name
        attributes {
            blockSectionTitle
            className
        }
    }
`;