import React from "react";
import { graphql } from "gatsby";
import {buildBlocks} from "../../../services/builder";

export const CoreGalleryBlock = (props) => {
  const blocks = buildBlocks(props.innerBlocks, props.parentAttributes, props.pageContext);

  return <>
    <figure className={`wp-block-gallery has-nested-images columns-${props?.attributes?.columns} is-cropped`}>
      <ul className="blocks-gallery-grid">

        {blocks?.map((block) => {
            return <li className="blocks-gallery-item">{block}</li>
        })}
      </ul>
      </figure>
  </>

  //   const classes = useStyles();
  //   if( ! props.attributes) return false;
  //   return <GridList
  //     cellHeight={'auto'}
  //     className={`${props.attributes.className ? props.attributes.className : ''} ${classes.gallery}`}
  //     cols={props.attributes.columns ? props.attributes.columns : 2 }
  //   >

  //   {props.attributes.images && props.attributes.images.map((tile) => (
  //     <GridListTile key={tile.id}>
  //       {/* {tile.fullUrlSharp ?
  //         (tile.fullUrlSharp.childImageSharp ?
  //           <Img fluid={tile.fullUrlSharp.childImageSharp.fluid} alt={tile.alt} />
  //           :
  //           <img src={tile.fullUrlSharp.publicURL} alt={tile.alt}/>
  //         )
  //       : */}
  //           <div className="gatsby-image-wrapper">
  //               <div style={{ paddingBottom: '100%' }} />
  //               <img
  //                   src={tile.url}
  //                   alt={tile.alt}
  //                   style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center center' }}
  //               />
  //           </div>
  //       {/* } */}

  //       {tile.caption ?
  //           <GridListTileBar
  //             title={tile.caption}
  //           >
  //               {ReactHtmlParser(tile.caption, {transform : transformLink})}
  //           </GridListTileBar>
  //       : ''}
  //     </GridListTile>
  //   ))}
  // </GridList>
};

// CoreGalleryBlock.propTypes = {
//     attributes: PropTypes.object.isRequired,
// };

export const CoreGalleryBlockFragment = graphql`
  fragment CoreGalleryBlock on WpBlock {
    ...on WpCoreGalleryBlock {
      name
      originalContent
      attributes {
        __typename
        ...on WpCoreGalleryBlockAttributes {
          align
          caption
          className
          columns
          ids
          imageCrop
          images {
            alt
            caption
            fullUrl
            id
            link
            url
          }
          linkTo
        }
      }
    }
  }`;

  // This is the string version of this that is used within previews and without WP prefixes
export const CoreGalleryBlockPreview = `
...on CoreGalleryBlock {
  name
  originalContent
  attributes {
    __typename
    ...on CoreGalleryBlockAttributes {
      align
      caption
      className
      columns
      ids
      imageCrop
      images {
        alt
        caption
        fullUrl
        id
        link
        url
      }
      linkTo
    }
  }
}
`;
