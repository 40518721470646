import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Button from "@material-ui/core/Button";
import Link from "../../Link";
import { trackFileView } from "../../../services/gtm";
import { makeStyles } from "@material-ui/styles";
import styles from "./styles";

const useStyles = makeStyles((theme) => styles(theme));

export const CoreFileBlock = (props) => {
  const classes = useStyles();
  if (props?.attributes?.showDownloadButton === true) {
    return (
      <Link
        to={process.env.GATSBY_ADMIN_URL + props?.attributes?.href}
        style={{ textDecoration: "none" }}
        className={classes.file}
      >
        <Button
          variant={"contained"}
          title={props?.attributes?.textLinkTarget}
          style={{ margin: "6px" }}
          color={
            props?.attributes?.backgroundColor
              ? props?.attributes?.backgroundColor
              : "primary"
          }
          className={`${props?.attributes?.className} ${
            props?.attributes?.align ? `align-${props?.attributes?.align}` : ""
          }`}
        >
          {props?.attributes?.downloadButtonText}
        </Button>
      </Link>
    );
  } else {
    return (
      <Link
        to={process.env.GATSBY_ADMIN_URL + props?.attributes?.href}
        onClick={() => trackFileView(props?.attributes?.href)}
        forceBlank={true}
        className={`${
          props?.attributes?.className ? props?.attributes?.className : ""
        } ${props?.attributes?.align ? `align-${props?.attributes?.align}` : ""} ${
          classes.file
        }`}
      >
        {props?.attributes?.fileName}
      </Link>
    );
  }
};

CoreFileBlock.propTypes = {
  attributes: PropTypes.object.isRequired,
};

export const CoreFileBlockFragment = graphql`
  fragment CoreFileBlock on WpBlock {
    ... on WpCoreFileBlock {
      name
      attributes {
        __typename
        ... on WpCoreFileBlockAttributes {
          align
          className
          downloadButtonText
          fileName
          href
          id
          showDownloadButton
          textLinkHref
          textLinkTarget
        }
      }
    }
  }
`;

// This is the string version of this that is used within previews and without WP prefixes
export const CoreFileBlockPreview = `
...on CoreFileBlock {
  name
  attributes {
    __typename
    ...on CoreFileBlockAttributes {
      align
      className
      downloadButtonText
      fileName
      href
      id
      showDownloadButton
      textLinkHref
      textLinkTarget
    }
  }
}
`;
